<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          <myegrn-logo />
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Ваш новый пароль
          </b-card-title>
          <b-card-text class="mb-2">
            <span class="text-danger">{{ newPassword }}</span>
            <br>
            <br>
            Внимание! Рекомендуем сменить сгенерированный пароль на придуманый Вами в личном кабинете.
          </b-card-text>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Вернуться к входу
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import MyegrnLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg,
} from 'bootstrap-vue'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    MyegrnLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BCardTitle,
    BCardText,
  },
  data() {
    return {
      newPassword: 'загрузка...',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    this.LoadingPassword()
  },
  methods: {
    LoadingPassword() {
      // console.log(this.$route.params.hash)
      const vm = this
      useJwt.forgotPasswordSuccess({
        hash: this.$route.params.hash,
      })
        .then(response => {
          // console.log('response', response)
          vm.newPassword = response.data.Data.password
        })
        .catch(p1 => {
          // console.log('error', p1)
          try {
            if (p1.response.Date.data) {
              this.$refs.registerForm.setErrors(p1.response.Date.data.error)
            } else {
              this.$refs.registerForm.setErrors('Сервер не доступен, повторите попытку позднее')
            }
          } catch {
            this.$refs.registerForm.setErrors(p1)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
